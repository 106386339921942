window.onscroll = function () {
    scrollFunction()
};

const headerlinks = document.querySelectorAll(".headerlink");
const hamburger = document.querySelectorAll(".line");
const navigation = document.getElementById("mobile-navigation");
const HamburgerMenu = document.getElementById("openmenu_hamburger");
const header = document.getElementById("header");
const logo = document.getElementById("guidos_name");
const season_wrapper = document.getElementById("change-season");
const season_text = document.getElementById("change-season-text");

function scrollFunction() {
    if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        header.classList.add("headerscroll");
        headerlinks.forEach(headerlink => headerlink.classList.add("linksscroll"));
        hamburger.forEach(line => line.classList.add("hamburgerscroll"));
        logo.classList.add("logoscroll");
        season_wrapper.classList.add("seasonscroll");
        season_text.classList.add("seasontextscroll");
    } else {
        header.classList.remove("headerscroll");
        headerlinks.forEach(headerlink => headerlink.classList.remove("linksscroll"));
        hamburger.forEach(line => line.classList.remove("hamburgerscroll"));
        logo.classList.remove("logoscroll");
        season_wrapper.classList.remove("seasonscroll");
        season_text.classList.remove("seasontextscroll");
    }
}

function OpenMobileNavHomepage() {
    if (HamburgerMenu.classList.contains('active') && window.location.pathname == "/fr/") {
        header.style.background = "none";
        document.getElementById("guidos_name").style.color = "#fff";
        hamburger.forEach(line => line.style.stroke = "#fff");
    } else if (HamburgerMenu.classList.contains('active') && window.location.pathname == "/de/") {
        header.style.background = "none";
        document.getElementById("guidos_name").style.color = "#fff";
        hamburger.forEach(line => line.style.stroke = "#fff");
    } else {
        header.style.background = "#fff";
        document.getElementById("guidos_name").style.color = "#212548";
        hamburger.forEach(line => line.style.stroke = "#00AB84");
    }
}

HamburgerMenu.addEventListener("click", () => {
    OpenMobileNavHomepage();
});